<template>
  <div class="login">
		<h1 v-if="!blockedUser">Ingresar a mi cuenta</h1>
		<h1 v-else>Cuenta bloqueada</h1>
		<div class="form-container">
			<form action="">
				<div class="form-field-vertical" :class="[blockedUser ? 'disabled' : loginError ? 'error' : '']">
					<label for="">Usuario</label>
					<input type="email"
						v-model="user.email"
						:invalid="isEmailInvalid"
						:disabled="blockedUser"
						placeholder="Email"
						@input="resetErrors"
					>
				</div>
				<div class="form-field-vertical" :class="[blockedUser ? 'disabled' : loginError ? 'error' : '']">
					<label for="">Contraseña</label>
					<input type="password"
						v-model="user.password"
						:invalid="loginError && !blockedUser"
						:disabled="blockedUser"
						placeholder="Escribí tu contraseña"
						@input="resetErrors"
						@keyup.enter="login()"
					>
					<p
						class="hint red"
						v-if="loginError"
					>
						Credenciales incorrectas.
					</p>
					<p
						class="hint green"
						v-else
						@click="resetPassword"
					>
						¿Olvidaste tu contraseña?
					</p>
				</div>
				<div
					v-if="blockedUser"
					class="alert alert-blue"
				>
					<svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g clip-path="url(#clip0_98_46)">
						<path d="M7.99992 14.6666C11.6818 14.6666 14.6666 11.6819 14.6666 7.99998C14.6666 4.31808 11.6818 1.33331 7.99992 1.33331C4.31802 1.33331 1.33325 4.31808 1.33325 7.99998C1.33325 11.6819 4.31802 14.6666 7.99992 14.6666Z" stroke="#3939B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M8 10.6667V8" stroke="#3939B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M8 5.33331H8.00667" stroke="#3939B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</g>
						<defs>
						<clipPath id="clip0_98_46">
						<rect width="16" height="16" fill="white"/>
						</clipPath>
						</defs>
					</svg>
					<p class="alert-title">¿Cómo desbloquear mi cuenta?</p>
					<p class="alert-text">Debido a tres intentos fallidos de inicio de sesión, tu cuenta ha sido bloqueada temporalmente para garantizar la seguridad de tu información. Para restaurar el acceso, por favor comunícate con el administrador de Tita.</p>
				</div>
				<div class="btn-container">
					<button
						type="button"
						class="btn btn-primary btn-lg"
						:disabled="!isEmailValid || user.password.length == 0 || blockedUser"
						@click="login()"
					>
						Ingresar
					</button>
				</div>
			</form>
		</div>
  </div>
</template>

<script setup>
import { useContentStore } from '@/stores/index.js'
</script>
<script>
export default {
  name: 'LoginView',
	data() {
		return {
			emailRegex: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+)$/,
			loginError: null,
			failedAttempts: 0,
			maxAttempts: 3,
			blockedUser: false,
			user: {
				email: '',
				password: '',
			}
		}
	},
	
	computed: {
		isEmailInvalid() {
			if (this.isEmailValid) return null
			else return true
		},

		isEmailValid() {
			if (
				(this.user.email.length == 0 || this.user.email?.match(this.emailRegex)?.length > 0)
				& !this.loginError
			) return true
			else return null
		}
	},

	methods: {
		blockUser() {
			fetch(process.env.VUE_APP_URL_API + 'api/backofficeUser/block', {
				method: 'POST',
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "application/json",
				}), 
				body: JSON.stringify({email: this.user.email})
			})
				.then(response => response.json())
				.then(response => this.onBlockedUser(response))
				.catch(error => console.error('Error:', error))
		},

		onBlockedUser(data) {
			// temporario hasta definir comportamiento de bloqueo de usuario si el usuario no existe
			if (data.code == 'BLOCKED') {
				this.blockedUser = true
				this.loginError = false
			}
		},

		login() {
			if (this.isEmailValid && this.user.password.length > 0 && !this.blockedUser) {
				fetch(process.env.VUE_APP_URL_API + 'api/backofficeUser/login', {
					method: 'POST',
					headers: new Headers({
						'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
						"Content-Type": "application/json",
					}), 
					body: JSON.stringify(this.user)
				})
					.then(response => response.json())
					.then(response => this.onLogin(response))
					.catch(error => console.error('Error:', error))
			}
		},

		onLogin(data) {
			if (data.resultDto.code == 'SUCCESS') {
				useContentStore().user = data.backofficeUserDto
				useContentStore().user.isLoggedIn = true
				this.$router.push({name: 'Home'})
			}

			else {
				if (data.resultDto.code == 'BLOCKED') {
					this.blockedUser = true
				}

				if (data.resultDto.code == 'INVALID_EMAIL') {
					this.loginError = true
				}

				if (data.resultDto.code == 'INVALID_PASSWORD') {
					this.loginError= true
					this.failedAttempts++
				}

				if (this.failedAttempts >= this.maxAttempts) {
					this.blockUser()
				}
			}
		},

		resetErrors() {
			this.loginError = null
			this.isEmailValid = null
		},

		resetPassword() {
			alert('próximamente: resetear contraseña')
		}
	}
}
</script>