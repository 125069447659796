<template>
	<div class="modal" v-if="show">
		<div class="modal-content">
			<h1>Cargar imagen</h1>
			<input type="file" @change="fileChange" ref="file" />
			<p v-if="imageSrc.length > 0 && !validImage">Archivo no válido</p>
			<div class="btn-container">
				<button
					class="btn btn-secondary btn-sm"
					@click="show = false;"
				>
					Cancelar
				</button>
				<button
					class="btn btn-primary btn-sm"
					@click="insertImage"
					:disabled="!validImage"
				>
					Cargar
				</button>
			</div>

		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			imageSrc: '',
			command: null,
			show: false,
		};
	},

	computed: {
		validImage() {
			return (
				this.imageSrc?.match(/\.(jpeg|jpg|gif|png|svg)$/) != null
			);
		}
	},

	methods: {
		showModal(command) {
			this.command = command;
			this.show = true;
		},

		fileChange() {
			const myHeaders = new Headers();
			// myHeaders.append("Content-Type", "multipart/form-data"); --> NO USAR, ROMPE EL REQUEST
			myHeaders.append("Authorization", 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'));
			const formdata = new FormData();
			formdata.append("file", this.$refs.file.files[0]);
			const requestOptions = {
				method: "POST",
				headers: myHeaders,
				body: formdata,
				redirect: "follow"
			};
			fetch(process.env.VUE_APP_URL_API + 'api/article/image/upload', requestOptions)
				.then(response => response.text())
				.then(data => this.imageSrc = data)
				.catch((error) => console.error(error))
		},

		insertImage() {
			// const data = {
			//   command: this.command,
			//   data: {
			// 		src: url,
			// 		alt: "",
			// 		title: ""
			//   }
			// }

			this.$emit('onConfirm', this.imageSrc);
			this.closeModal();
		},

		closeModal() {
			this.show = false;
			this.imageSrc = "";
		}
	}
};
</script>

<style scoped>
.modal {
	display: grid;
	place-items: center;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 9;
}

.modal-content {
	background-color: var(--color-n0);
	padding: 2em;
	border-color: 8px;
}

input {
	margin: 2em 0 3em;
}
</style>
