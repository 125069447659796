<template>
	<div class="backoffice-view editor-view">
		<div class="page-header flex-v gap4">
			<span
				v-if="mode == 'edit'"
				class="badge"
				:class="user.state == 'BLOCKED' ? 'bg-orange' : ''"
			>
				{{ user.state == 'ENABLED' ? 'Activo' : user.state == 'BLOCKED' ? 'Bloqueado' : ''}}
			</span>
			<h1>{{ mode == 'create' ? 'Agregar colaborador' : user.username }}</h1>
			<p v-if="mode == 'create'">Completá los datos para agregar un nuevo colaborador.</p>
		</div>
		<main>
			<form action="">
				<div class="actions flex-h-end" v-if="isNotMe && mode == 'edit'">
					<button
						v-if="user.state == 'ENABLED'"
						class="btn btn-tertiary"
						@click="blockUser"
					>
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g clip-path="url(#clip0_527_9569)">
						<path d="M8.00016 14.6667C11.6821 14.6667 14.6668 11.6819 14.6668 8.00004C14.6668 4.31814 11.6821 1.33337 8.00016 1.33337C4.31826 1.33337 1.3335 4.31814 1.3335 8.00004C1.3335 11.6819 4.31826 14.6667 8.00016 14.6667Z" stroke="#15663D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M6 10L10 6" stroke="#15663D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</g>
						<defs>
						<clipPath id="clip0_527_9569">
						<rect width="16" height="16" fill="white"/>
						</clipPath>
						</defs>
					</svg>
						Bloquear
					</button>
					<button
						v-if="user.state == 'BLOCKED'"
						class="btn btn-tertiary"
						@click="unblockUser"
					>
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g clip-path="url(#clip0_527_9949)">
							<path d="M7.99992 14.6666C11.6818 14.6666 14.6666 11.6818 14.6666 7.99992C14.6666 4.31802 11.6818 1.33325 7.99992 1.33325C4.31802 1.33325 1.33325 4.31802 1.33325 7.99992C1.33325 11.6818 4.31802 14.6666 7.99992 14.6666Z" stroke="#15663D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M6 8.00008L7.33333 9.33341L10 6.66675" stroke="#15663D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							</g>
							<defs>
							<clipPath id="clip0_527_9949">
							<rect width="16" height="16" fill="white"/>
							</clipPath>
							</defs>
						</svg>
						Activar
					</button>
					<button
						class="btn btn-tertiary"
						@click="deleteUser(user.email)"
					>
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M2 4H14" stroke="#15663D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M12.6668 4V13.3333C12.6668 14 12.0002 14.6667 11.3335 14.6667H4.66683C4.00016 14.6667 3.3335 14 3.3335 13.3333V4" stroke="#15663D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M5.3335 4.00004V2.66671C5.3335 2.00004 6.00016 1.33337 6.66683 1.33337H9.3335C10.0002 1.33337 10.6668 2.00004 10.6668 2.66671V4.00004" stroke="#15663D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M6.6665 7.33337V11.3334" stroke="#15663D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M9.3335 7.33337V11.3334" stroke="#15663D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
						Eliminar
					</button>
				</div>
				<div class="form-field-vertical">
					<label>Nombre</label>
					<input type="text" v-model="user.username" placeholder="¿Cuál es su nombre?">
				</div>
				<div class="form-field-vertical">
					<label>Email</label>
					<input
						type="email"
						v-model="user.email"
						:valid="isEmailValid"
						:invalid="isEmailInvalid"
						placeholder="¿Cuál es su email?"
					>
				</div>
				<div class="form-field-vertical">
					<label>Rol</label>
					<VueSelect
						v-model="user.roleCode"
						:options="roles.map(role => ({'label': role.description, 'value': role.code}))"
						placeholder="Elegí un rol"
					/>
				</div>
				<div
					class="display-contents"
					v-if="mode == 'create' || mode == 'reset'"
				>
					<div class="form-field-vertical">
						<label>Generar contraseña</label>
						<div class="password-generator">
							<input
								type="text"
								v-model="user.password"
							>
							<button
								type="button"
								class="btn btn-secondary btn-sm"
								@click="generatePassword"
							>
								Generar
							</button>
						</div>
					</div>
				</div>
				<div class="btn-container">
					<button
						type="button"
						class="btn btn-secondary btn-lg"
						@click="discardChanges()"
					>
						Cancelar
					</button>
					<button
						type="button"
						class="btn btn-primary btn-lg"
						@click="saveUserChanges()"
						:disabled="!isFormValid"
					>
						{{ mode == 'create' ? 'Agregar' : 'Guardar cambios' }}
					</button>
				</div>
			</form>
		</main>
	</div>
</template>

<script setup>
import { useContentStore } from '@/stores/index.js'
import VueSelect from "vue3-select-component"
import "vue3-select-component/dist/style.css"
</script>
<script>
export default {
	name: 'BackofficeUserCreate',
	data() {
		return {
			mode: undefined, // 'edit', 'create', 'reset'
			changed: false,
			emailRegex: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+)$/,
			user: {
				roleCode: '',
				username: '',
				email: '',
				state: '',
				password: '',
			},
		}
	},

	watch: {
		user: {
			handler(newData, oldData) {
				if (oldData.username !== '') {
					this.changed = true
				}
			},
			deep: true 
		}
	},

	computed: {
		isFormValid() {
			for (const key in this.user) {
				if ((key == 'password' && this.mode == 'edit') || (key == 'state' && this.mode == 'create')) {
					continue
				}

				if (this.user[key]?.length == 0 || this.user[key] == null) {
					return false
				}
			}

			return this.isEmailValid
		},

		isEmailValid() {
			if (this.user.email?.length > 1 && this.user.email?.match(this.emailRegex)?.length > 0) {
				return true
			}

			else return null
		},

		isEmailInvalid() {
			if (this.user.email?.length > 0  && !this.user.email?.match(this.emailRegex)) {
				return true
			}

			else return null
		},

		isNotMe() {
			return this.user.backofficeUserId !== useContentStore().user.backofficeUserId
		},

		roles() {
			return useContentStore().roles
		}
	},

	methods: {
		generatePassword() {
			fetch(process.env.VUE_APP_URL_API + 'api/data/password', {
				method: 'GET',
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "text/plain;charset=UTF-8",
				}),
			})
				.then((response) => {
					if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.text()
        })
        .then((data) => this.user.password = data)
				.catch(error => console.error('Error:', error))
		},

		blockUser() {
			if (confirm("¿Querés bloquear a este usuario? Al bloquearlo tendrá restringido el acceso al Sistema de gestión de Tita.")) {
				this.user.state = 'BLOCKED'
				this.saveUserChanges()
			}	
		},

		discardChanges() {
			this.$router.push({name: 'BackofficeUsersList'})
		},

		saveUserChanges() {
			let url, method
			if (this.mode == 'edit' || this.mode == 'reset') { method = 'PUT', url = 'update'}
			else if (this.mode == 'create') { method = 'POST', url = 'new' }

			fetch(process.env.VUE_APP_URL_API + 'api/backofficeUser/' + url, {
				method: method,
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "application/json",
				}),
				body: JSON.stringify(this.user)
			})
				.then(response => response.json())
				.then(response => this.onUserCreated(response))
				.catch(error => console.error('Error:', error))
		},

		deleteUser(user) {
			if (window.confirm('¿Querés eliminar a este usuario? Al eliminarlo  tendrá restringido el acceso y dejará de estar visible en el listado de colaboradores del Sistema de gestión de Tita.')) {
				fetch(process.env.VUE_APP_URL_API + `api/backofficeUser/delete`, {
					method: 'PUT',
					headers: new Headers({
						'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
						"Content-Type": "application/json",
					}),
					body: JSON.stringify({email: user})
				})
					.then(response => response.json())
					.then(response => this.onDeleteBackofficeUser(response))
					.catch(error => console.error('Error:', error))
			}
		},

		onDeleteBackofficeUser(data) {
			if (data.code == 'SUCCESS') {
				this.$router.push({name: 'BackofficeUsersList'})
				alert('Usuario eliminado')
			}
		},

		onUserCreated(data) {
			if (data.resultDto.code == 'INVALID_EMAIL') {
				alert(data.resultDto.description)
			}

			else if (data.resultDto.code == 'VALID_EMAIL') {
				this.$router.push({name: 'BackofficeUsersList'})
			}
		},

		unblockUser() {
			if (confirm("¿Querés activar a este usuario? Al activarlo tendrá acceso al Sistema de gestión de Tita.")) {
				this.user.state = 'ENABLED'
				this.mode = 'reset'
			}	
		},
	},

	created() {
		if (this.$route.query.user) {
			this.mode = 'edit'
			fetch(process.env.VUE_APP_URL_API + `api/backofficeUser/findById/${this.$route.query.user}`, {
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "application/json",
				}),
			})
				.then(response => response.json())
				.then(data => this.user = data)
				.catch(error => console.error('Error:', error))
		}

		else {
			this.mode = 'create'
		}
	},

	mounted() {
		useContentStore().fetchBackofficeUserRoles()
	}
}
</script>

<style scoped>
.password-generator {
	width: 100%;
	position: relative;
}

.password-generator .btn {
	position: absolute;
	top: 8px;
	right: 14px;
}
</style>
