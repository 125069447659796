<template>
  <div class="backoffice-view list-view articles">
		<div class="page-header">
			<div class="title">
				<h1>Artículos</h1>
				<p>Seleccioná la sección sobre la que querés trabajar para observar el listado de los artículos cargados.  Podés editarlos, eliminarlos o crear uno nuevo desde acá. </p>
			</div>
			<router-link
					:to="{name:'ArticlesEditorView'}"
				>
					<button class="btn btn-primary btn-lg">
						Crear artículo
					</button>
				</router-link>
		</div>
		<div
			class="table-container"
		>
			<div class="table-tools">
				<input type="search" placeholder="Buscar un artículo" v-model="searchQuery">
			</div>
			<div class="tab-group">
				<button
					v-for="(artType, index) in sortedArticleTypes"
					:key="artType.order"
					:data-type-code="artType.code"
					class="tab"
					:class="index == 0 ? 'active' : ''"
					@click="clickTab"
				>
					{{ artType.description }}
				</button>
			</div>
			<div
				v-if="articlesByType.length == 0"
			>
				<p>No hay artículos cargados.</p>
			</div>
			<table v-else>
				<thead>
					<th>Artículo</th>
					<th>Editor</th>
					<th>Fecha</th>
					<th>Estado</th>
					<th>Acciones</th>
				</thead>
				<tbody>
					<tr
						v-for="article in filteredData"
						:key="article.articleId"
						:data-id="article.articleId"
					>
						<td>{{ article.title }}</td>
						<td>{{ article.userName }}</td>
						<td>{{ article.creationDate?.split('T')[0] || '-' }}</td>
						<td>
							<span
								class="badge"
								:class="article.state == 'DRAFT' ? '' : 'bg-orange'"
							>{{ article.state == 'PUBLISHED' ? 'Publicado' : 'Borrador'}}</span>
						</td>
						<td>
							<div class="btn-container">
								<button>
									<router-link
										:to="{
											name: 'ArticlesEditorView',
											query: {id: article.articleId}
										}"
									>
										<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<mask id="mask0_6_1147" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
											<rect width="24" height="24" fill="#D9D9D9"/>
											</mask>
											<g mask="url(#mask0_6_1147)">
											<path d="M5 19H6.4L15.025 10.375L13.625 8.975L5 17.6V19ZM19.3 8.925L15.05 4.725L16.45 3.325C16.8333 2.94167 17.3043 2.75 17.863 2.75C18.421 2.75 18.8917 2.94167 19.275 3.325L20.675 4.725C21.0583 5.10833 21.2583 5.571 21.275 6.113C21.2917 6.65433 21.1083 7.11667 20.725 7.5L19.3 8.925ZM17.85 10.4L7.25 21H3V16.75L13.6 6.15L17.85 10.4Z" fill="#595959"/>
											</g>
										</svg>
									</router-link>
								</button>
								<!-- <button
									@click="confirmDelete(article.articleId)"
								>
									<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<g clip-path="url(#clip0_6_1150)">
										<path d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM9 9H15C15.55 9 16 9.45 16 10V18C16 18.55 15.55 19 15 19H9C8.45 19 8 18.55 8 18V10C8 9.45 8.45 9 9 9ZM15.5 4L14.79 3.29C14.61 3.11 14.35 3 14.09 3H9.91C9.65 3 9.39 3.11 9.21 3.29L8.5 4H6C5.45 4 5 4.45 5 5C5 5.55 5.45 6 6 6H18C18.55 6 19 5.55 19 5C19 4.45 18.55 4 18 4H15.5Z" fill="#595959"/>
										</g>
										<defs>
										<clipPath id="clip0_6_1150">
										<rect width="24" height="24" fill="white"/>
										</clipPath>
										</defs>
									</svg>
								</button> -->
							</div>
						</td>
				</tr>
				</tbody>
			</table>
		</div>
  </div>
</template>

<script setup>
import { useContentStore } from '@/stores/index.js'
</script>

<script>
export default {
  name: 'ArticlesListView',
	data() {
		return {
			activeArticleTypeCode: undefined,
			searchQuery: ''
		}
	},

	computed: {
		articles() {
			return useContentStore().articles
		},

		articlesByType() {
			return useContentStore().articlesByTypeCode(this.activeArticleTypeCode)
		},

		articleTypes() {
			return useContentStore().articleTypes
		},

		filteredData() {
      return this.articlesByType.filter((art) => {
        return (
          art.title.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      });
    },

		sortedArticleTypes() {
			return this.articleTypes.sort(function(a, b) {
				return (a.code < b.code) ? -1 : (a.code > b.code) ? 1 : 0;
			});
		}
	},

	watch: {
		activeArticleTypeCode(newCode, oldCode) {
			if (newCode !== oldCode) {
				document.querySelectorAll('.tab').forEach(function(tab) {
					tab.classList.remove('active')
				})
				document.querySelector(`[data-type-code='${newCode}']`).classList.add('active')
			}
		}
	},

	methods: {
		clickTab(event) {
			if (!event.target.classList.contains('active')) {
				document.querySelectorAll('.tab').forEach(function(tab) {
					tab.classList.remove('active')
				})
				event.target.classList.add('active')
				this.activeArticleTypeCode = event.target.getAttribute('data-type-code')
			}
		},

		confirmDelete(id) {
			if (window.confirm('Borrar artículo?')) {
				fetch(process.env.VUE_APP_URL_API + `api/article/delete/${id}`, {
					method: 'PUT',
					headers: {
						"Content-Type": "application/json",
					},
				})
					.then(response => response.json())
					.then(response => console.log(response))
					.catch(error => console.error('Error:', error));
			}
		},

		getActiveState(article) {
			return article.active == 1 ? true : false
		}
	},

	created() {
		useContentStore().fetchArticles()
		useContentStore().fetchArticleTypes()
	},

	mounted() {
	},

	updated() {
		if (this.$route.query.articleId && this.$route.query.articleTypeCode) {
			this.activeArticleTypeCode = this.$route.query.articleTypeCode
			document.querySelector(`tr[data-id="${this.$route.query.articleId}"]`)?.classList.add('highlight')
			this.$router.replace({'query': null});
		}
	}

}
</script>
<style>
#app {
	display: flex;
}

.highlight td {
	animation-name: highlight;
	animation-duration: 3s;
	/* animation-delay: .2s; */
	animation-timing-function: ease-in-out;
	animation-iteration-count: 1;
}

@keyframes highlight {
	from {
		background-color: var(--color-g20);
	}

	to {
		background-color: var(--color-n0);
	}
}
</style>
