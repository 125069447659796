<template>
	<nav id="sidenav">
		<div id="navigation">
			<ul>
				<router-link
					:to="{name: 'Home'}"
				>
					<li>
						Inicio
					</li>
				</router-link>
				<router-link
					v-if="userRole == 'EDIT'"
					:to="{name: 'ArticlesListView'}"
				>
					<li>
						Artículos
					</li>
				</router-link>
				<router-link
					v-if="userRole == 'ADMIN'"
					:to="{name: 'BackofficeUsersList'}"
				>
					<li>
						Colaboradores
					</li>
				</router-link>
				<router-link
					v-if="userRole == 'ADMIN'"
					:to="{name: 'BackofficeRolesList'}"
				>
					<li>
						Roles
					</li>
				</router-link>
			</ul>
		</div>
	</nav>
</template>

<script>
import { useContentStore } from '@/stores/index.js'
export default {
	name: 'SideNav',
	computed: {
		userRole() {
			return useContentStore().user.roleCode
		}
	}
}
</script>